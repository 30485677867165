<template>
  <section>
    <Contact />
    <Action />
  </section>
</template>

<script>
// @ is an alias to /src

import Action from '@/modules/marketing/MarketingAction.vue';
import Contact from '@/modules/marketing/contact/MarketingContact';

export default {
  name: 'contact',
  components: { Contact, Action },
  layout: 'default',
  metaInfo: {
    title: 'Contact us ',
  },
};
</script>
