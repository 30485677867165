<template>
  <div id="hubspotContactForm" v-once></div>
</template>
<script>
export default {
  mounted() {
    if (window.hbspt) {
      window.hbspt.forms.create({
        portalId: '9184409',
        formId: '10b33781-3a0d-4eec-be55-b639ede39194',
        target: '#hubspotContactForm',
      });
    }
  },
};
</script>
