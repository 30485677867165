
import Vue from 'vue';
import Component from 'vue-class-component';
import service from '../../../services/app-http-client';
import ContactForm from '../forms/ContactForm.vue';

@Component({
  components: {
    ContactForm,
  },
})
export default class MarketingContact extends Vue {
  // showMobileMenu = false;
  // form = {
  //   subject: '',
  //   name: '',
  //   email: '',
  //   message: '',
  //   terms: false,
  // };
  // async sendMessage(): Promise<void> {
  //   await service.post('user/contact', this.form, {
  //     showNotify: true,
  //     notify: { title: 'Message sent', text: 'Thank you for your message, our support staff will get back to you as soon as possible' },
  //   });
  // }
}
